import { createReducer, on } from '@ngrx/store';
import { UserActions } from './user.actions';

export const userFeatureKey = 'user';

export interface State {
  status: string,
  userData: any,
  token: string | null
}

export const initialState: State = {
  status: 'pending',
  userData: null,
  token: null
};

export const reducer = createReducer(
  initialState,
  on(
    UserActions.loadUserSuccess,
    (state, action) => {
      return {
        ...state,
        userData: action.data,
        token: localStorage.getItem('token'),
        status: 'loggedIn'
      }
    }
  ),
  on(
    UserActions.loadUserFailure,
    (state, action) => {
      return {
        ...state,
        token: null,
        userData: null,
        status: 'loggedOut'
      }
    }
  ),
  on(
    UserActions.removeUserData,
    (state, action) => {
      return {
        ...state,
        token: null,
        userData: null,
        status: 'loggedOut'
      }
    }
  )
);

