import { JsonPipe, NgClass } from '@angular/common';
import { AfterViewChecked, Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { API_ROUTES } from 'src/app/core/constants/api.constants';
import { ApiHttpService } from 'src/app/core/services/api-http.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-chat',
  standalone: true,
  imports: [JsonPipe, NgClass, FormsModule, MatIcon],
  templateUrl: './chat.component.html',
  styleUrl: './chat.component.scss'
})
export class ChatComponent implements OnInit, AfterViewChecked{
  chatView = false;
  chatToggled = false;
  localSelectedFiles: File[] = [];

  @ViewChild('chatContainer') chatContainer?: ElementRef;
  private readonly api = inject(ApiHttpService);
  socket: any;
  messageList: any = [];

  ngOnInit(): void {
    this.api.get(API_ROUTES.getWebsocketChatToken).subscribe({
      next: (res:any) => {
        this.socket = new WebSocket(environment.WEBSOCKET_API+'?token='+res.data.token);
        this.socket.onmessage = (event: any) => {
          this.messageList.push(JSON.parse(event.data));
          const ChatMessageContainer = this.chatContainer?.nativeElement as HTMLDivElement;
          if (ChatMessageContainer) {
            setTimeout(() => {
              ChatMessageContainer.scrollTop = ChatMessageContainer.scrollHeight;
            }, 500)
          }
        }
      }
    })
  }

  ngAfterViewChecked(): void {
    if (this.chatToggled) {
      const ChatMessageContainer = this.chatContainer?.nativeElement as HTMLDivElement;
      if (ChatMessageContainer) {
        setTimeout(() => {
          ChatMessageContainer.scrollTop = ChatMessageContainer.scrollHeight;
        }, 500)
      }
      this.chatToggled = false;
    }
  }

  sendMessage(input: HTMLInputElement) {
    if (this.localSelectedFiles.length !== 0) {
      this.uploadImageAndSendMessage(input.value, this.localSelectedFiles)
      input.value = ''
      this.localSelectedFiles = []
      return;
    }
    if (input.value === '') return;
    this.sendMessageEvent(input.value, [])
    input.value = ''
  }

  uploadImageAndSendMessage(text: string, files: File[]) {
    if (files && files.length > 0) {
      const data = new FormData();
      for (let i = 0; i < files.length; i++) {
        data.append(`images[${i}]`, files[i]);
      }
      this.api.post(API_ROUTES.uploadMediaForChat, data).subscribe({
        next: (res: any) => this.sendMessageEvent(text, res.data),
      });
    }
  }

  sendMessageEvent(text: string, fileData: any[]) {
    const sendMessage = {
      event: "send_message",
      message: {
        text: text,
        files: fileData
      }
    };
    this.socket.send(JSON.stringify(sendMessage));
  }

  addImage(event: Event) {
    const files = (event.target as HTMLInputElement).files
    if (files) {
      for (let i = 0; i < files.length; i++) {
        this.localSelectedFiles.push(files[i])
      }
    }
  }

  get getLocalImages(): string[] {
    return this.localSelectedFiles.map(file => URL.createObjectURL(file))
  }

  triggerChat() {
    this.chatView = !this.chatView;
    this.chatToggled = true;
  }

}
