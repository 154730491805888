import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent {
  video: SafeResourceUrl = '';

  constructor(
    private _sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) private _video: string
  ) {}

  ngOnInit() {
    let embedUrl = this._video;
    if (this._video.match('https://www.youtube.com/shorts/')) {
      embedUrl = this._video.replace(
        'https://www.youtube.com/shorts/',
        'https://www.youtube.com/embed/'
      );
    }
    if (this._video.match('https://youtu.be/')) {
      embedUrl = this._video.replace(
        'https://youtu.be/',
        'https://www.youtube.com/embed/'
      );
    }
    if (this._video.match('https://www.youtube.com/watch')) {
      embedUrl = this._video
        .replace(
          'https://www.youtube.com/watch?v=',
          'https://www.youtube.com/embed/'
        )
        .replace('&ab_channel=Foreveryng', '');
    }
    this.video = this._sanitizer.bypassSecurityTrustResourceUrl(embedUrl);
  }
}
