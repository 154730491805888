import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SlugService } from './slug.service';

@Injectable({
  providedIn: 'root',
})
export class ClickRouteService {
  constructor(private _router: Router, private _slug: SlugService) {}

  handleClick(
    attribute: string,
    filter: NonNullable<unknown> | null,
    id: number | null,
    title = '',
    slug = '',
    afflilateId: number | null = null,
    affiliate_type = ''
  ) {
    if (afflilateId) {
      localStorage.setItem('affiliate_type', affiliate_type);
      localStorage.setItem('affiliate_id', afflilateId.toString());
    } else {
      localStorage.removeItem('affiliate_type');
      localStorage.removeItem('affiliate_id');
    }
    if (!slug) {
      slug = this._slug.makeSlug(title);
    }
    switch (attribute) {
      case null:
        break;
      case 'cart':
        this._router.navigate(['/cart']);
        break;
      case 'category':
        this._router.navigate(['/category', slug, id]);
        break;
      case 'brand':
        this._router.navigate(['/brand', slug, id]);
        break;
      case 'brand_profile':
        this._router.navigate(['/not-found']);
        break;
      case 'product':
        this._router.navigate(['product', slug, id]);
        break;
      case 'offer':
        this._router.navigate(['/not-found']);
        break;
      case 'filter':
        this._router.navigate(['/search'], { queryParams: filter });
        break;
      case 'spinAndWin':
        this._router.navigate(['/spin-and-win']);
        break;
      case 'essentials':
        this._router.navigate(['/not-found']);
        break;
      case 'accessories':
        this._router.navigate(['/not-found']);
        break;
      case 'banks_terms_and_conditions':
        this._router.navigate(['/not-found']);
        break;
      case 'company_profile':
        this._router.navigate(['/not-found']);
        break;
      case 'selectable_free_gifts':
        this._router.navigate(['/not-found']);
        break;
      case 'campaign':
        this._router.navigate(['/not-found']);
        break;
      case 'refer_and_earn':
        this._router.navigate(['/profile/refer']);
        break;
      case 'collaboration':
        this._router.navigate(['/not-found']);
        break;
      case 'makeup_artist':
        this._router.navigate(['/not-found']);
        break;
      case 'booking':
        this._router.navigate(['/not-found']);
        break;
      case 'campaign_ui':
        this._router.navigate(['/campaign', id]);
        break;
      case 'gift_card_home_page':
        this._router.navigate(['gift-card']);
        break;
      default:
        this._router.navigate(['/not-found']);
        break;
    }
  }
}
