import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const CategoryActions = createActionGroup({
  source: 'Category',
  events: {
    'Load Categories': emptyProps(),
    'Load Categories Success': props<{ data: any }>(),
    'Load Categories Failure': props<{ error: any }>(),
  }
});
