<div class="mainproduct_blocks" [ngClass]="variation">
    <div class="imageproduct_sections">
        <div class="product_sales_tags" *ngIf="productData.isBestSeller">
            <p>Best Seller</p>
        </div>
        <div class="quick-delivery" *ngIf="productData.quickDelivery.isQuickDeliverable">
            <img [src]="productData.quickDelivery.quickDeliverableIcon" alt="" height="35px">
        </div>
        <img class="cover_image" [src]="productData.coverImage" [routerLink]="['/product', productData.slug, productData.id]" alt="">
        <div class="product_block_new_rating">
            <span>{{productData.ratingAndReview.averageRating}} <img src="../../../../assets/icons/star-fill.svg" alt=""> <strong>/5 ({{ productData.ratingAndReview.total }})</strong></span>
        </div>
    </div>

    <div class="product_details_cnts">
        <div class="sales_tags_prod_block" *ngIf="productData.isDiscounted">{{ productData.discountPercent }}</div>
        <div class="price_product_block">
            <span class="discounted_price">{{ productData.discountedPrice | nepaliCurrency }}</span>
            <span class="main_price" *ngIf="productData.isDiscounted">{{ productData.price | nepaliCurrency }}</span>
        </div>
        <div class="offers_validations freegifts" *ngIf="productData.productFreeGift.available">
            <img src="../../../../assets/images/icon-gift-white.svg" alt="Foreveryng Product Offers">
            <span>Free Gift Inside</span>
        </div>
        <div class="offers_validations">
            <img src="../../../../assets/images/icon-offers-productblocks.svg" alt="Foreveryng Product Offers">
            <span>More Offer Inside</span>
        </div>
        <h2 [routerLink]="['/product', productData.slug, productData.id]">{{ productData.name | clampText: 45 }}</h2>
    </div>

    <div class="expire" *ngIf="productData.clearance && productData.product_expiry_date">
        <img loading="lazy" src="../../../../assets/icons/expire_icon.svg"
            alt="Buy Makeup Products Online | Foreveryng">Expires in {{ productData.product_expiry_date*1000 | date }}
    </div>
    <div class="product_actions">
        <div class="wrap_products_sections">
            <a href="javascript:void(0)" class="addtocart_btn view_cart" *ngIf="isCart" [routerLink]="['/cart']">View Cart</a>
            <a class="addtocart_btn" href="javascript:void(0)" *ngIf="! isCart && productData.stockQuantity > 0"
                (click)="addToCart(productData.attributeProductId, productData.id, productData.slug)"><img src=""
                    alt="">Add To Cart</a>
            <a class="addtocart_btn outof_stocks" href="javascript:void(0)" *ngIf="productData.stockQuantity <= 0">Out Of Stocks</a>
            <a href="javascript:void(0)"
                (click)="addToWishlist(productData.attributeProductId, productData.id, productData.slug)"
                class="wishlist-iconslist" *ngIf="! isWishlist">
                <img class="wishlist-unselected-prod" src="../../../../assets/images/icon-wishlist-prod.svg" alt="">
            </a>
            <a href="javascript:void(0)"
                (click)="removeFromWishlist(productData.attributeProductId, productData.id, productData.slug)"
                class="wishlist-iconslist" *ngIf="isWishlist">
                <img class="wishlist-selected-prod" src="../../../../assets/images/icon-wishlist-prod-fill.svg" alt="">
            </a>
        </div>


    </div>
</div>
