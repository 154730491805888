export const API_ROUTES = {
    home: "/api/v3/home",
    homeBanners: "/api/v3/getSectionBanners",
    homeProducts: "/api/v3/getSectionProducts",
    homeBrands: "/api/v3/getSectionBrands",
    homeCategories: "/api/v3/getSectionCategories",
    loginUser: "/api/loginUser",
    userData: "/api/userDetails",
    logoutUser: "/api/logoutUser",
    registerUser: "/api/registerUser",
    getCart: "/api/getCart",
    productDetail: "/api/product",
    addToCart: "/api/addToCart",
    updateCart: "/api/updateCart",
    filter: "/api/V2/filter",
    couponList: "/api/getCouponList",
    addressList: "/api/getDeliveryAddresses",
    paymentGateways: "/api/paymentGateways/get-gateways",
    checkout: "/api/checkout",
    orderList: "/api/getAllOrders",
    order: "/api/getSingleOrder",
    cities: "/api/getCitiesList",
    addAddress: "/api/addDeliveryAddress",
    updateAddress: "/api/updateDeliveryAddress",
    deleteAddress: "/api/deleteDeliveryAddress",
    esewaPaymentVerify: "/api/esewaPaymentVerify",
    searchSuggestions: "/api/getSearchSuggestions",
    cancelOrderProduct: "/api/cancelOrderProduct",
    removeFromWishlist: "/api/removeFromWishlist",
    myPayPayment: "/api/mypayGenerateOrder",
    khaltiPayment: "/api/initiateKhaltiPayment",
    autoSearch: "/api/autoSearch",
    brandList: "/api/getBrandList",
    categoriesList: "/api/getCategoriesList",
    wishlist: "/api/getWishlist",
    productReviews: "/api/product/review",
    writeReview: "/api/addOrUpdateReview",
    deleteReviewImage: "/api/deleteReviewImage",
    deleteReview: "/api/deleteReview",
    userReviews: "/api/getUserReviews",
    verifyPasswordResetToken: "/api/verifyPasswordResetToken",
    forgotPassword: "/api/forgotPassword",
    resetPassword: "/api/resetPassword",
    setFCM: "/api/setFCMToken",
    termsAndConditons: "/api/getTermsAndConditions",
    privateCoupons: "/api/getPrivateCoupons",
    notifications: "/api/notifications",
    offers: "/api/offers",
    socialLogin: "/api/socialLogin",
    notifyMe: "/api/updateStockNotificationWishlist",
    changePassword: "/api/userUpdatePassword",
    selectableGiftList: "/api/selectable-free-gifts/home",
    selectGift: "/api/selectable-free-gifts/select-gift",
    getSubscriptions: "/api/getUserSubscriptions",
    verifyPhoneNumber: "/api/verifyPhoneNumber",
    verifyPhoneNumberToken: "/api/verifyPhoneNumberToken",
    rewardHistory: "/api/getRewardHistory",
    cancelSubscription: "/api/cancelSubscription",
    updateUserDetails: "/api/updateUserDetails",
    getFaqs: "/api/getFaqs",
    contact: "/api/contactUs",
    getAllPosts: "/api/posts/get-posts",
    getSinglePost: "/api/posts/",
    searchPost: "/api/posts/",
    giftCardsHome: "/api/gift-cards/home",
    getGiftCard: "/api/gift-cards/get-details",
    listGiftCards: "/api/gift-cards/list-gift-cards",
    purchaseGiftCard: "/api/gift-cards/purchase",
    giftCardsOrdersList: "/api/gift-cards/get-all-purchases",
    giftCardPurchaseDetail: "/api/gift-cards/get-purchase-detail",
    giftCardSubmitCorporateRequest: "/api/gift-cards/corporate/sumbit-request",
    giftCardMyPay: "/api/gift-cards/mypayGenerateOrder",
    giftCardKhalti: "/api/gift-cards/initiateKhaltiPayment",
    giftCardEsewa: "/api/gift-cards/esewaPaymentVerify",
    giftCardTandC: "/api/gift-cards/terms-and-conditions",
    giftCardHowToUse: "/api/gift-cards/how-to-use",
    getQuotes: "/api/quotes/getQuotes",
    campaign: "/api/campaign-ui",
    applyGiftCard: "/api/applyGiftCard",
    membershipDetail: "/api/getMembershipList",
    influencer: "/api/collaboration/home",
    spinAndWinOffers: "/api/v2/spin-and-win/list-offers",
    spinAndWinClaimOffer: "/api/spin-and-win/claim-offer",
    spinAndWinTerms: "/api/getSpinAndWinTermsAndConditions",
    claimDailyReward: "/api/claimDailyVisitReward",
    deleteAccount: "/api/deleteAccount",
    privacy: "/api/getPrivacyPolicy",
    getProvinces: "/api/getProvincesList",
    getCities: "/api/getCitiesList",
    getAreas: "/api/getAreasList",
    loginContents: "/api/getLoginContents",
    claimNewUserReward: "/api/collectNewUserReward",
    trackOrder: "/api/getSingleOrderTracking",
    searchAreaAddress: "/api/searchArea",
    getWebsocketChatToken: "/api/chat/getUserToken",
    uploadMediaForChat: "/api/chat/uploadMedia",
};
