import { createReducer, on } from '@ngrx/store';
import { HomeActions } from './home.actions';

export const homeFeatureKey = 'home';

export interface State {
  homeData: any,
  bannerData: any,
  brandData: any,
  categoryData: any,
  productData: any
}

export const initialState: State = {
  homeData: null,
  bannerData: null,
  brandData: null,
  categoryData: null,
  productData: null
};

export const reducer = createReducer(
  initialState,
  on(
    HomeActions.homeDataLoadSuccess,
    (state, actions) => {
      return {
        ...state,
        homeData: actions.data
      }
    }
  ),
  on(
    HomeActions.homeBannersLoadSuccess,
    (state, actions) => {
      return {
        ...state,
        bannerData: actions.data
      }
    }
  ),
  on(
    HomeActions.homeBrandsLoadSuccess,
    (state, actions) => {
      return {
        ...state,
        brandData: actions.data
      }
    }
  ),
  on(
    HomeActions.homeCategoriesLoadSuccess,
    (state, actions) => {
      return {
        ...state,
        categoryData: actions.data
      }
    }
  ),
  on(
    HomeActions.homeProductsLoadSuccess,
    (state, actions) => {
      return {
        ...state,
        productData: actions.data
      }
    }
  ),
  on(
    HomeActions.closePopUp,
    (state, actions) => {
      return {
        ...state,
        homeData: {
          ...state.homeData,
          popup: {
            ...state.homeData.popup,
            showPopUp: false
          }
        }
      }
    }
  ),
);

