import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, map, shareReplay } from 'rxjs';
import { product } from 'src/app/core/interface/product';
import { AppState } from 'src/app/store/app.store';
import { CartActions } from 'src/app/store/cart/cart.actions';
import { getUserStatus } from 'src/app/store/user/user.selectors';
import { WishlistActions } from 'src/app/store/wishlist/wishlist.actions';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {
  @Input() productData: product = {
    id: 0,
    name: '',
    slug: '',
    coverImage: '',
    wishlist: false,
    wishlistId: 0,
    attributeProductId: 0,
    attributeProductCount: 0,
    isInsideCart: false,
    stock_notification_list: false,
    type: '',
    stockQuantity: 0,
    price: 0,
    discountedPrice: 0,
    isDiscounted: false,
    discountPercent: '',
    ratingAndReview: {
      total: 0,
      averageRating: 0
    },
    isBestSeller: false,
    productFreeGift: null,
    isCrossBorderProduct: false,
    clearance: false,
    product_expiry_date: null,
    quickDelivery: {
      isQuickDeliverable: false,
      quickDeliverableIcon: '',
      quickDeliverableText: '',
    }
  };

  @Input() variation = 'normal';
  isWishlist = false;
  isCart = false;

  isHandset$: Observable<boolean> = this._breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    private _breakpointObserver: BreakpointObserver,
    private _store: Store<AppState>,
    private _router: Router
  ) { }

  addToCart(attribute_id: number, product_id: number, slug: string) {
    this._store.select(getUserStatus).subscribe(
      status => {
        if (status == 'loggedIn') {
          window.fbq('track', 'addToCart', {
            content_type: 'product',
            content_name: this.productData.name,
            content_ids: [this.productData.id],
            currency: 'NPR',
            value: this.productData.discountedPrice.toFixed(2)
          });
          this.isCart = true;
          this._store.dispatch(CartActions.addToCart({ id: attribute_id, quantity: 1 }));
          this.isWishlist = false;
        } else {
          this._router.navigate(['/product', slug, product_id]);
        }
      }
    )
  }

  ngOnInit() {
    this.isWishlist = this.productData?.wishlist;
    this.isCart = this.productData?.isInsideCart;
  }

  addToWishlist(id: number, product_id: number, slug: string) {
    this._store.select(getUserStatus).subscribe(
      status => {
        if (status == 'loggedIn') {
          this.isWishlist = true;
          window.fbq('track', 'addToWishlist', {
            content_type: 'product',
            content_name: this.productData.name,
            content_ids: [this.productData.id],
            currency: 'NPR',
            value: this.productData.discountedPrice.toFixed(2)
          });
          this._store.dispatch(WishlistActions.addToWishlist({ attribute_product_id: id }));
          this.isCart = false;
        } else {
          this._router.navigate(['/product', slug, product_id]);
        }
      }
    )
  }

  removeFromWishlist(id: number, product_id: number, slug: string) {
    this._store.select(getUserStatus).subscribe(
      status => {
        if (status == 'loggedIn') {
          this.isWishlist = false;
          this._store.dispatch(WishlistActions.removeFormWishlist({ product_id: id }));
        } else {
          this._router.navigate(['/product', slug, product_id]);
        }
      }
    )
  }

  notifyMe(id: number) {
    this._store.dispatch(WishlistActions.notifyMe({ attribute_product_id: id }));
  }
}
