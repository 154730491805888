export const environment = {
    API_ENDPOINT: 'https://api.stage-foreveryng.site',
    // API_ENDPOINT: 'http://localhost:8080',
    WEBSOCKET_API: "wss://ws.stage-foreveryng.site",
    // WEBSOCKET_API: "http://localhost:49500/chat",
    FIREBASE_CONFIG: {
        projectId: 'foreveryng-app',
        appId: '1:892785807070:web:b520aaaccb996677c07c4f',
        databaseURL: 'https://foreveryng-app.firebaseio.com',
        storageBucket: 'foreveryng-app.appspot.com',
        apiKey: 'AIzaSyBkjDw2XDnHqAVkmfiEjJXM8FP89ZesRKc',
        authDomain: 'foreveryng-app.firebaseapp.com',
        messagingSenderId: '892785807070',
        measurementId: 'G-11NR3KCY22',
    },
    FIREBASE_DATA: {
        subscribe: 'websitetest',
        vapidKey: 'BEUHkNlFUKSsjkeytVP5zLKOfnqKi7XIVIg45vA-pS5n3Tmop019zIdUgoSYt_7jg99lcnWvXv1grMOlrnPQ3YQ',
        serverKey: 'AAAAz94uTt4:APA91bEMY1b51KLqyDIk4AuPgHdG0xyl66DxonTnrJYljy2j7XpHeRLTTTg9dFmryHApP-nVc_LOd3Dcjq8LuSAJj7yOQ5BQkTikdFtbPWeL-3IwmpJywvwmMGiR7kHceXzvjvxqXbWS'
    },
    PAYMENT_URL: {
        ESEWA: "https://rc-epay.esewa.com.np/api/epay/main/v2/form",
        ESEWA_SCD: 'EPAYTEST',
        ESEWA_SECRET_KEY: '8gBm/:&EnhH.1/q',
    },
};
