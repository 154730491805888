import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const UserActions = createActionGroup({
  source: 'User',
  events: {
    'Load User': emptyProps(),
    'Load User Success': props<{ data: any }>(),
    'Load User Failure': props<{ error: any }>(),
    'Remove User Data': emptyProps(),
    'Edit User Profile': props<{ params: any }>(),
  }
});
