import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CdkService {
  constructor(
    private readonly _breakpoinObserver: BreakpointObserver
  ) { 
    this._breakpoinObserver.observe(Breakpoints.Handset).pipe(
      map(res => res.matches),
      shareReplay()
    ).subscribe(
      res => {
        this.deviceView$.next(res ? 'mobile': 'website')
      }
    );
  }
  private readonly deviceView$: BehaviorSubject<string> = new BehaviorSubject<string>('');


  get deviceView() {
    return this.deviceView$.value;
  }
}
