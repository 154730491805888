import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nepaliCurrency'
})
export class NepaliCurrencyPipe implements PipeTransform {

  transform(value: number): unknown {
    const number = value.toString();
    if (number.length < 3) return 'Rs. '+ number;
    const afterThousandUnit = number.slice(number.length-3);
    let beforeThousandUnit = number.slice(0,number.length-3);
    let firstOddNumber = '';
    if (beforeThousandUnit.length % 2) {
      firstOddNumber = beforeThousandUnit.slice(0, 1);
      beforeThousandUnit = beforeThousandUnit.slice(1);
    }
    let outputString = "";
    for (let i = 0; i < beforeThousandUnit.length; i += 2) {
      const slice = beforeThousandUnit.slice(i, i + 2);
      if (slice.length == 2)
        outputString += slice + ',';
      else
        outputString += slice;
    }
    if (firstOddNumber.length === 1) {
      outputString = firstOddNumber + ',' + outputString;
    }
    return 'Rs. '+ outputString + '' + afterThousandUnit;
  }
}
