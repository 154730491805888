<input type="text" placeholder="search address" (keyup)="filterAddress($event)">
<ul *ngIf="filteredList; else loading">
    <li *ngFor="let address of filteredList">
        <button (click)="selectAddress(address)">{{ address.area_name }}, {{ address.city_name }}, {{ address.province_name }}</button>
    </li>
</ul>

<ng-template #loading>
    <ul>
        <li class="shimmer" *ngFor="let _ of [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]"><pre> </pre></li>
    </ul>
</ng-template>