import { createReducer, on } from '@ngrx/store';
import { CategoryActions } from './category.actions';

export const categoryFeatureKey = 'category';

export interface State {
  data: any
}

export const initialState: State = {
  data: null
};

export const reducer = createReducer(
  initialState,
  on(
    CategoryActions.loadCategoriesSuccess,
    (state, action) => {
      return {
        ...state,
        data: action.data
      }
    }
  )
);

