<ng-container *ngIf="viewPopup">
    <div class="main-container">
        <div class="popup-container">
            <button (click)="popupClose()" class="close-btn">
                <img loading="lazy" width="100%" height="100%" src="../../../../assets/icons/icon-close.svg" alt="Buy Makeup Products Online | Foreveryng">
            </button>
            <div class="popup">
                <ng-container [ngSwitch]="popuptype">
                    <ng-template *ngSwitchCase="'newUser'" [ngTemplateOutlet]="newUser"></ng-template>
                    <ng-template *ngSwitchCase="'remindNewUser'" [ngTemplateOutlet]="remindNewUser"></ng-template>
                    <!-- <ng-template *ngSwitchCase="'dailyReward'" [ngTemplateOutlet]="dailyReward"></ng-template> -->
                    <ng-template *ngSwitchCase="'homePopup'" [ngTemplateOutlet]="homePopup"></ng-template>
                </ng-container>
            </div>
        </div>
    </div>
    <div (click)="popupClose()" class="overlay"></div>
</ng-container>


<ng-template #newUser>
    <div class="new-user-reward-popup">
        <img [src]="homeData.newUserRewardPopUp.image" alt="" width="250px">
        <h3 class="title">{{ homeData.newUserRewardPopUp.title }}</h3>
        <p>
            {{ homeData.newUserRewardPopUp.body }}
        </p>
        <button (click)="claimNewUserReward()">Claim Reward</button>
    </div>
</ng-template>
<ng-template #remindNewUser>
    <div class="new-user-reward-popup" (click)="remindNewUserPopupClicked()">
        <img [src]="homeData.newUserPromoCodeUsageReminderPopUp.image" alt="" width="250px">
        <h3 class="title">{{ homeData.newUserPromoCodeUsageReminderPopUp.title }}</h3>
        <p>
            {{ homeData.newUserPromoCodeUsageReminderPopUp.body }}
        </p>
    </div>
</ng-template>
<ng-template #dailyReward>
    <div class="claim-daily-reward-popup">
        <h3 class="title">{{ homeData.dailyVisitRewardPopUp.title }}</h3>
        <p>
            {{ homeData.dailyVisitRewardPopUp.body }}
        </p>
        <button (click)="claimDailyReward()">Claim Reward</button>
    </div>
</ng-template>
<ng-template #homePopup>
    <img (click)="homePopupClicked()" width="100%" class="home-popup" [src]="homeData.popup.webImage" alt="">
</ng-template>
