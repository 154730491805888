import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { MaterialModule } from './material/material.module';
import { RouterModule } from '@angular/router';
import { OrderDetailsComponent } from './components/order-details/order-details.component';
import { DeliveryAddressComponent } from './components/delivery-address/delivery-address.component';
import { StarRatingsComponent } from './components/star-ratings/star-ratings.component';
import { LoaderComponent } from './components/loader/loader.component';
import { DashboardNavComponent } from './components/dashboard-nav/dashboard-nav.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { FooterComponent } from './components/footer/footer.component';
import { NepaliCurrencyPipe } from "src/app/core/pipes/nepali-currency.pipe";
import { ClampTextPipe } from 'src/app/core/pipes/clamp-text.pipe';


@NgModule({
  declarations: [
    VideoPlayerComponent,
    ProductCardComponent,
    OrderDetailsComponent,
    DeliveryAddressComponent,
    StarRatingsComponent,
    LoaderComponent,
    FooterComponent,
    DashboardNavComponent,
    ConfirmationDialogComponent,
    NepaliCurrencyPipe,
    ClampTextPipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule
  ],
  exports: [
    VideoPlayerComponent,
    ProductCardComponent,
    OrderDetailsComponent,
    DeliveryAddressComponent,
    StarRatingsComponent,
    FooterComponent,
    DashboardNavComponent,
    LoaderComponent,
    NepaliCurrencyPipe,
  ]
})
export class SharedModule { }
