import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  getToken() {
    return localStorage.getItem('token');
  }

  setToken(data: string) {
    localStorage.setItem('token', data)
  }

  removeToken() {
    localStorage.removeItem('token');
  }

  setFCMToken(token: string) {
    localStorage.setItem('fcm_token', token);
  }

  getFCMToken() {
    return localStorage.getItem('fcm_token');
  }
}
