import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../app.store';
import { addressFeatureKey } from './address.reducer';


const getAddress = createFeatureSelector<AppState>(addressFeatureKey);

export const getAddressData = createSelector(
    getAddress,
    (state: any) => state.data
)

export const getCities = createSelector(
    getAddress,
    (state: any) => state.cities
)