import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { EMPTY, ErrorNotification, Observable, map } from 'rxjs';
import { API_ROUTES } from 'src/app/core/constants/api.constants';
import { SearchAreaInterface, address, city } from 'src/app/core/interface/address';
import { ApiHttpService } from 'src/app/core/services/api-http.service';
import { AddressActions } from 'src/app/store/address/address.actions';
import { AppState } from 'src/app/store/app.store';
import { CartActions } from 'src/app/store/cart/cart.actions';
import { SearchAddressComponent } from '../search-address/search-address.component';

@Component({
  selector: 'app-add-address',
  templateUrl: './add-address.component.html',
  styleUrls: ['./add-address.component.scss']
})
export class AddAddressComponent {
  addressForm = this._fb.group({
    name: [null, Validators.required],
    address: [null, Validators.required],
    phone: [null, Validators.required],
    alt_phone: [null],
    province_id: [null, Validators.required],
    city_id: [null, Validators.required],
    area_id: [null, Validators.required],
    is_default: [false],
  });

  selectedAddress?: SearchAreaInterface;

  constructor(
    private _fb: FormBuilder,
    private _api: ApiHttpService,
    private _dialogRef: MatDialogRef<AddAddressComponent>,
    private _dialog: MatDialog,
    private _store: Store<AppState>
  ) { }

  submitNewAddress() {
    this.addressForm.markAllAsTouched();
    if (this.addressForm.valid) {
      const addressSub = this._api.post(API_ROUTES.addAddress, this.addressForm.value).subscribe({
        next: () => {
          this._store.dispatch(AddressActions.loadAddress());
          this._store.dispatch(CartActions.reloadCart());
        },
        complete: () => {
          addressSub.unsubscribe();
          this._dialogRef.close();
        },
        error: (err: ErrorNotification) => {
          addressSub.unsubscribe();
        }
      });
    }
  }

  searchAddress() {
    this._dialog.open(SearchAddressComponent).afterClosed().subscribe(res => {
      if (res) {
        this.selectedAddress = res.address;
        this.addressForm.get('province_id')?.setValue(res.address.province_id);
        this.addressForm.get('city_id')?.setValue(res.address.city_id);
        this.addressForm.get('area_id')?.setValue(res.address.area_id);
      }
    })
  }
}
