import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EMPTY, Observable } from 'rxjs';
import { AddAddressComponent } from './add-address/add-address.component';
import { EditAddressComponent } from './edit-address/edit-address.component';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.store';
import { getAddressData } from 'src/app/store/address/address.selectors';
import { CartActions } from 'src/app/store/cart/cart.actions';
import { address } from 'src/app/core/interface/address';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {
  addressData$: Observable<address[]> = EMPTY;

  constructor (
    private _store: Store<AppState>,
    private _matDialog: MatDialog,
    private _dialogRef: MatDialogRef<AddressComponent>
  ) {}

  ngOnInit() {
    this.addressData$ = this._store.select(getAddressData);
  }

  addAddress() {
    this._matDialog.open(AddAddressComponent);
    this._dialogRef.close();
  }

  editAddress(address: address) {
    this._matDialog.open(EditAddressComponent, {
      data: { address: address }
    })
  }

  selectAddress(id: number) {
    this._store.dispatch(CartActions.updateCart({ params: { delivery_address_id: id } }));
    this._dialogRef.close();
  }
}
