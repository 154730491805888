import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../app.store';
import { homeFeatureKey } from './home.reducer';

const getHome = createFeatureSelector<AppState>(homeFeatureKey);

export const getHomeData = createSelector(
    getHome,
    (state: any) => state.homeData
)

export const getHomeBanners = createSelector(
    getHome,
    (state: any) => state.bannerData
)

export const getHomeBrands = createSelector(
    getHome,
    (state: any) => state.brandData
)

export const getHomeCategories = createSelector(
    getHome,
    (state: any) => state.categoryData
)

export const getHomeProducts = createSelector(
    getHome,
    (state: any) => state.productData
)