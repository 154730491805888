<div class="address-list" *ngIf="addressData$ | async as addressData">
    <div class="address-item" *ngFor="let address of addressData">
        <div class="address-data" tabindex="0" (click)="selectAddress(address.id)" (keypress)="selectAddress(address.id)">
            <div class="name">
                name: {{address.name}}
            </div>
            <div class="phone">
                phone: {{address.phone}}
            </div>
            <div class="province">
                province: {{ address.province?.name }}
            </div>
            <div class="city">
                City: {{address.city.name}}
            </div>
            <div class="area">
                Area: {{ address.area?.name }}
            </div>
            <div class="address">
                address: {{address.address}}
            </div>
            <div class="default" *ngIf="address.isDefault">
                Default Address
            </div>
        </div>
        <div class="edit-action">
            <button (click)="editAddress(address)" (keypress)="editAddress(address)">Edit</button>
        </div>
    </div>
</div>
<div class="buttonwrap_addresslist">
    <button (click)="addAddress()" (keypress)="addAddress()">Add Address</button>
</div>