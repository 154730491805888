<div class="handpick-deals-newraps" *ngIf="dataType === 'reason_to_buy'">
    <div class="container_handpickdeals">

        <div class="wraphandpick_mainsecs_cnt">
            <div class="handpickdeals_newsec" *ngFor="let section of homeSectionData;index as i">
                <h2>Get your {{ section.title }} Products </h2>
                <div [ngClass]="'wrapblocks_handpicks handpick_theme'+(i+1)">
                    <div class="blocks_handpick_deals" *ngFor="let option of section.options">
                        <!-- handpickdeals_bgcolor1 -->
                        <a [routerLink]="['/search']" [queryParams]="{ 'max_price' : option.value }">
                            <div class="handpickdeals_cnts">
                                <span>Under Rs</span>
                                <h3>{{ option.value }}</h3>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="dataType === 'youtubeVideos'">
    <div class="youtube_containersec">
        <div class="container">
            <div class="wrap_youtubinstsec">
                <div class="title_youtubesecs">
                    <div class="tittleheadinglink">
                        <h2>Social Feed</h2>
                        <p>Welcome to our social feed page, your go-to destination for beauty, skincare, and makeup.
                            Discover product reviews, skincare routines, makeup tutorials, and expert tips for healthy
                            skin and flawless makeup. Get inspired, informed, and empowered on your beauty journey!</p>
                    </div>
                    <span><a target="_blank" href="https://www.instagram.com/iforeveryng/"><img loading="lazy"
                                src="../../../../assets/icons/icon-instagram.svg"
                                alt="Buy Makeup Product Online | Foreveryng">#iforeveryng</a></span>
                </div>

            </div>
        </div>
        <div class="list_social_feeds"
            [ngStyle]="{ '--feedLength': (isHandset$ | async) ? 0 : homeSectionData.length, 'overflow' : (isHandset$ | async) ? 'auto' : 'hidden' }">
            <div class="blocks_socialfeeds_sec" *ngFor="let youtube of homeSectionData" tabindex="0"
                (click)="openVideo(youtube.url)" (keypress)="openVideo(youtube.url)">
                <div class="image">
                    <img loading="lazy"  [src]="youtube.thumbnail" alt="Buy Makeup Product Online | Foreveryng">
                </div>
                <div class="title">
                    {{youtube.title}}
                </div>
                <div class="duration">
                    {{youtube.duration}}
                </div>
            </div>
            <ng-container *ngIf="(isHandset$ | async) === false">
                <div class="blocks_socialfeeds_sec" *ngFor="let youtube of homeSectionData" tabindex="0"
                    (click)="openVideo(youtube.url)" (keypress)="openVideo(youtube.url)">
                    <div class="image">
                        <img loading="lazy"  [src]="youtube.thumbnail" alt="Buy Makeup Product Online | Foreveryng">
                    </div>
                    <div class="title">
                        {{youtube.title}}
                    </div>
                    <div class="duration">
                        {{youtube.duration}}
                    </div>
                </div>
                <div class="blocks_socialfeeds_sec" *ngFor="let youtube of homeSectionData" tabindex="0"
                    (click)="openVideo(youtube.url)" (keypress)="openVideo(youtube.url)">
                    <div class="image">
                        <img loading="lazy"  [src]="youtube.thumbnail" alt="Buy Makeup Product Online | Foreveryng">
                    </div>
                    <div class="title">
                        {{youtube.title}}
                    </div>
                    <div class="duration">
                        {{youtube.duration}}
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="dataType === 'app_promo'">
    <div class="section_apppromotions">
        <div class="container">
            <div class="wrap_apppromo_secs">
                <div class="image">
                    <img loading="lazy"  width="608" height="590" src="../../../../assets/images/app-promo/app-promition-image.png"
                        alt="Buy Makeup Product Online | Foreveryng">
                </div>

                <div class="app_promotions_details">
                    <h2>EXPERIENCE <span>OUR APP</span></h2>
                    <p>
                        Where shopping for your favorite beauty products becomes an exhilarating adventure! Prepare to
                        embark on a journey that combines the joy of discovering new trends with the convenience of
                        online shopping. Our app is designed to immerse you in a world of glamour, making your
                        experience not only seamless but also fun and exciting.
                    </p>
                    <br>
                    <p>
                        Unlock exclusive offers and rewards as you explore the app, enhancing your shopping experience
                        even further. Browse through a wide range of premium beauty brands and products, all carefully
                        curated to satisfy your every desire. From luxurious skincare essentials to mesmerizing makeup
                        collections,
                        our app is your gateway to indulgence.
                    </p>
                    <div class="applinks_sections">
                        <a target="_blank"
                            href="https://play.google.com/store/apps/details?id=com.view9.foreveryng&hl=en&gl=US">
                            <img loading="lazy"  src="../../../../assets/images/app-promo/playstore.png"
                                alt="Buy Makeup Product Online | Foreveryng">
                        </a>
                        <a target="_blank" href="https://apps.apple.com/us/app/foreveryng-beauty-shopping/id1507192226">
                            <img loading="lazy"  src="../../../../assets/images/app-promo/appstore.png"
                                alt="Buy Makeup Product Online | Foreveryng">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="dataType === 'popup'">
    <div class="popup_section" *ngIf="viewPopUp && homeSectionData?.showPopUp">
        <div class="overlay" tabindex="0" (click)="closePopUp()" (keypress)="closePopUp()"></div>
        <div class="popup_container">
            <img loading="lazy"  src="../../../../assets/icons/icon-close.svg" alt="Buy Makeup Products Online | Foreveryng"
                tabindex="0" (click)="closePopUp()" (keypress)="closePopUp()" class="close">
            <div class="image" (click)="redirect.handleClick(homeSectionData?.attribute, homeSectionData?.filter, homeSectionData?.id, 'banner-redirect', 'banner-redirect')">
                <img loading="lazy"  [src]="homeSectionData?.image" *ngIf="isHandset$ | async"
                    alt="Buy Makeup Product Online | Foreveryng">
                <img loading="lazy"  [src]="homeSectionData?.webImage" *ngIf="(isHandset$ | async) === false"
                    alt="Buy Makeup Product Online | Foreveryng">
            </div>
        </div>
    </div>
</ng-container>
