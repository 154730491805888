import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'clampText'
})
export class ClampTextPipe implements PipeTransform {

  transform(value: string, ...args: number[]): string {
    if (value.length > args[0]) {
      return value.slice(0,args[0]) + '...';
    }
    return value;
  }

}
