import { createFeatureSelector, createSelector } from '@ngrx/store';
import { userFeatureKey } from './user.reducer';
import { AppState } from '../app.store';

const getUser = createFeatureSelector<AppState>(userFeatureKey);

export const getUserData = createSelector(
    getUser,
    (state: any) => {
        return state.userData;
    }
)

export const getUserStatus = createSelector(
    getUser,
    (state: any) => {
        return state.status;
    }
)

export const getReferCode = createSelector(
    getUser,
    (state: any) => {
        if (state.userData) {
            return state.userData.referAndEarn;
        }
    }
)