import { NgStyle } from '@angular/common';
import { Component, Input, OnInit, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { banner } from 'src/app/core/interface/banner';
import { CdkService } from 'src/app/core/services/cdk.service';
import { ClickRouteService } from 'src/app/core/services/click-route.service';

@Component({
  selector: 'app-banner-carousel',
  standalone: true,
  imports: [NgStyle],
  templateUrl: './banner-carousel.component.html',
  styleUrl: './banner-carousel.component.scss'
})
export class BannerCarouselComponent implements OnInit {
  @Input({ required: true }) banners!: banner[];
  @Input({ required: true }) width: string = '100%';
  @Input({ required: true }) height: string = '100%';
  counter$ = new BehaviorSubject<number>(0);

  activeImage = 0;

  private readonly redirect = inject(ClickRouteService);
  private readonly cdk = inject(CdkService);
  deviceView = this.cdk.deviceView;

  ngOnInit(): void {
    setInterval(() => this.counter$.next(this.counter$.getValue() + 1), 1000);
    this.counter$.subscribe(
      count => {
        if (count > 5) {
          this.nextImage();
          this.counter$.next(0);
        }
      }
    )
  }

  nextImage() {
    this.activeImage = (this.activeImage + 1) % this.banners.length;
    this.counter$.next(0);
  }

  prevImage() {
    this.activeImage--;
    if (this.activeImage < 0) {
      this.activeImage = this.banners.length + this.activeImage;
    }
    this.counter$.next(0);
  }

  redirectBanner(banner: banner) {
    this.redirect.handleClick(banner.attribute, banner.filter, banner.id, banner.title)
  }
}
