<div class="web-footer" *ngIf="(isHandset$ | async) === false">
    <div class="container">
        <div class="features">
            <div class="feature">
                <div class="icon" style="background-color: #FDEAF0;">
                    <img loading="lazy" src="../../../../assets/images/footer/shipping.svg" width="46" height="29"
                        alt="Buy Makeup Product Online | Foreveryng">
                </div>
                <div class="text" style="color: #7D5763;">
                    <div class="title">
                        Free Shipping
                    </div>
                    <div class="description">
                        All orders over Rs. 1000
                    </div>
                </div>
            </div>
            <div class="feature">
                <div class="icon" style="background-color: #EBF3E6;">
                    <img loading="lazy" src="../../../../assets/images/footer/cod.svg" width="42" height="28"
                        alt="Buy Makeup Product Online | Foreveryng">
                </div>
                <div class="text" style="color: #6B7B61;">
                    <div class="title">
                        Cash On Delivery
                    </div>
                    <div class="description">
                        Pay after you get it
                    </div>
                </div>
            </div>
            <div class="feature">
                <div class="icon" style="background-color: #F0D8F8;">
                    <img loading="lazy" src="../../../../assets/images/footer/privacy.svg" width="38" height="43"
                        alt="Buy Makeup Product Online | Foreveryng">
                </div>
                <div class="text" style="color: #704C7C;">
                    <div class="title">
                        100% Privacy
                    </div>
                    <div class="description">
                        Your Privacy Is Concerned
                    </div>
                </div>
            </div>
            <div class="feature">
                <div class="icon" style="background-color: #E7F2F5;">
                    <img loading="lazy" src="../../../../assets/images/footer/replacement.svg" width="50" height="54"
                        alt="Buy Makeup Product Online | Foreveryng">
                </div>
                <div class="text" style="color: #3C5258;">
                    <div class="title">
                        Free Replacement
                    </div>
                    <div class="description">
                        All orders are freely replaced
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="consultant-container">
        <div class="container">
            <div class="offer">
                <div class="question">
                    Find Your Perfect Match
                </div>
                <div class="description">
                    Unlock your beauty potential with our free consultations. Our expert team will help you find the
                    perfect beauty and skincare products for your unique needs. Say goodbye to beauty confusion and
                    hello to a personalized routine that's as unique as you are! Discover your ideal match and embrace a
                    personalized beauty routine that leaves you feeling confident and radiant.
                </div>
            </div>
            <div class="actions">
                <div class="call">
                    <a href="tel:+1234567890"> Have Enquries? Call Us</a>
                </div>
                <div class="email">
                    <a href="mailto:support@foreveryng.com">Email your Queries</a>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="info-links">
            <div class="col">
                <div class="logo">
                    <img loading="lazy" src="../../../../assets/images/footer/logo.svg" width="140" height="87"
                        alt="Buy Makeup Product Online | Foreveryng">
                </div>
                <div class="info">
                    Foreveryng, a subsidiary of Seven Multi Trading Pvt. Ltd., was founded with a vision to empower people by helping their inner beauty shine through.
                    As Nepal's largest beauty e-commerce platform, we offer a curated selection of leading international
                    and local beauty brands, along with a commitment to providing high-quality, genuine products and
                    easy returns policy.
                </div>
                <div class="social">
                    <a target="_blank" href="https://www.facebook.com/iforeveryng" rel="nofollow">
                        <img loading="lazy" src="../../../../assets/images/footer/facebook.svg" width="19" height="31"
                            alt="Buy Makeup Product Online | Foreveryng">
                    </a>
                    <a target="_blank" href="https://www.instagram.com/iforeveryng" rel="nofollow">
                        <img loading="lazy" src="../../../../assets/images/footer/instagram.svg" width="29" height="31"
                            alt="Buy Makeup Product Online | Foreveryng">
                    </a>
                    <a target="_blank" href="https://www.tiktok.com/@iforeveryng" rel="nofollow">
                        <img loading="lazy" src="../../../../assets/images/footer/tiktok.svg" width="28" height="31"
                            alt="Buy Makeup Product Online | Foreveryng">

                    </a>
                </div>
            </div>
            <div class="col">
                <div class="title">
                    FY Categories
                </div>
                <div class="list">
                    <a *ngFor="let category of (categories$ | async)?.slice(0, 9)"
                        [routerLink]="['/category', category.slug, category.id]">
                        {{ category.title }}
                    </a>
                </div>
            </div>
            <div class="col">
                <div class="title">
                    Help & Support
                </div>
                <div class="list">
                    <a [routerLink]="['/contact-us']">
                        Contact Us
                    </a>
                    <a [routerLink]="['/FAQs']">
                        Frequently Asked Question
                    </a>
                    <a [routerLink]="['/shipping-policy']">
                        Shipping & Delivery
                    </a>
                </div>
            </div>
            <div class="col">
                <div class="title">
                    Useful Links
                </div>
                <div class="list">
                    <a [routerLink]="['/about-us']">
                        Who are we ?
                    </a>
                    <a [routerLink]="['/reviews']">
                        Reviews
                    </a>
                    <!-- <a [routerLink]="['/']">
                        Customer Support
                    </a> -->
                    <!-- <a [routerLink]="['/']">
                        New and Foreveryng
                    </a> -->
                    <a [routerLink]="['/terms-and-conditions']">
                        Terms and Conditions
                    </a>
                    <a [routerLink]="['/privacy-policy']">
                        Privacy Policy
                    </a>
                </div>
            </div>
        </div>
        <hr>
        <div class="copyright" *ngIf="home$ | async as home">
            <div>
                {{ home.additionalInfo.copyrightInfo }}
            </div>
            <div style="opacity: 0.5;">
                Terms & Conditions | Shipping Policy | Cancellation Policy
            </div>
        </div>
    </div>
</div>

<div class="handset-footer" *ngIf="isHandset$ | async">

    <div class="features">
        <div class="feature">
            <div class="icon" style="background-color: #FDEAF0;">
                <img loading="lazy" src="../../../../assets/images/footer/shipping.svg"
                    alt="Buy Makeup Product Online | Foreveryng">
            </div>
            <div class="text" style="color: #7D5763;">
                <div class="title">
                    Free Shipping
                </div>
                <div class="description">
                    All orders over Rs. 1000
                </div>
            </div>
        </div>
        <div class="feature">
            <div class="icon" style="background-color: #EBF3E6;">
                <img loading="lazy" src="../../../../assets/images/footer/cod.svg"
                    alt="Buy Makeup Product Online | Foreveryng">
            </div>
            <div class="text" style="color: #6B7B61;">
                <div class="title">
                    Cash On Delivery
                </div>
                <div class="description">
                    Pay after you get it
                </div>
            </div>
        </div>
        <div class="feature">
            <div class="icon" style="background-color: #F0D8F8;">
                <img loading="lazy" src="../../../../assets/images/footer/privacy.svg"
                    alt="Buy Makeup Product Online | Foreveryng">
            </div>
            <div class="text" style="color: #704C7C;">
                <div class="title">
                    100% Privacy
                </div>
                <div class="description">
                    Your Privacy Is Concerned
                </div>
            </div>
        </div>
        <div class="feature">
            <div class="icon" style="background-color: #E7F2F5;">
                <img loading="lazy" src="../../../../assets/images/footer/replacement.svg"
                    alt="Buy Makeup Product Online | Foreveryng">
            </div>
            <div class="text" style="color: #3C5258;">
                <div class="title">
                    Free Replacement
                </div>
                <div class="description">
                    All orders are freely replaced
                </div>
            </div>
        </div>
    </div>

    <div class="actions">
        <div class="call">
            Have Enquries? Call Us
        </div>
        <div class="email">
            Email your Queries
        </div>
    </div>
    <div class="copyright" *ngIf="home$ | async as home">
        <div>
            {{ home.additionalInfo.copyrightInfo }}
        </div>
    </div>
</div>