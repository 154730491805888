import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const AddressActions = createActionGroup({
  source: 'Address',
  events: {
    'Load Address': emptyProps(),
    'Load Address Success': props<{ data: any }>(),
    'Load Cities': emptyProps(),
    'Load Cities Success': props<{ data: any }>(), 
    'Add Address': props<{ params: any }>(),
    'Edit Address': props<{ params: any }>(),
    'Delete Address': props<{ delivery_address_id: number }>()
  }
});
