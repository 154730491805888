import { Component, OnInit, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { API_ROUTES } from 'src/app/core/constants/api.constants';
import { SearchAreaInterface } from 'src/app/core/interface/address';
import { ApiHttpService } from 'src/app/core/services/api-http.service';

@Component({
  selector: 'app-search-address',
  templateUrl: './search-address.component.html',
  styleUrls: ['./search-address.component.scss']
})
export class SearchAddressComponent implements OnInit {
  private readonly dialogRef = inject(MatDialogRef<SearchAddressComponent>);
  private readonly api = inject(ApiHttpService);
  addressList: SearchAreaInterface[] = [];
  filteredList?: SearchAreaInterface[];

  ngOnInit(): void {
    this.api.get(API_ROUTES.searchAreaAddress).subscribe({
      next: (res: any) => {
        this.addressList = res.data;
        this.filteredList = res.data;
      }
    })
  }

  selectAddress(address: SearchAreaInterface) {
    this.dialogRef.close({ address: address })
  }

  filterAddress(ev: KeyboardEvent) {
    const query = (ev.target as HTMLInputElement).value.toLowerCase().split(' ');
    this.filteredList = [];
    let resultwithcount: any[] = [];
    for (let index = 0; index < this.addressList.length; index++) {
      const address = this.addressList[index];
      let count = 0;
      for (let index = 0; index < query.length; index++) {
        const part = query[index];
        if (
          part &&
          (
            address.area_name.toLowerCase().includes(part) ||
            address.city_name.toLowerCase().includes(part) ||
            address.province_name.toLowerCase().includes(part)
          )
        ) {
          count++;
        }
      }
      if (count) {
        resultwithcount.push({ address: address, count: count });
      }
    };
    this.filteredList = resultwithcount.sort((a, b) => b.count - a.count).map(res => res.address);
  }
}
