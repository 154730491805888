<ng-container>
    <div class="order_detailscarts_sections">
        <h3><img loading="lazy" src="../../../../assets/icons/cart-bag.svg"
                alt="Buy Makeup Product Online | Foreveryng"> Order Summary</h3>
        <div class="details_sections_orders">
            <p><span>Orders Total:</span> <strong>{{details?.subTotal | nepaliCurrency }}</strong></p>
            <p><span>Shipping Charge:</span> <strong>+ {{details?.shippingCharge | nepaliCurrency }}</strong></p>
            <p><span>Total Discount:</span> <strong>- {{details?.billingTotalDiscount | nepaliCurrency }}</strong></p>
            <p *ngIf="details?.appliedRewardPoints"><span>Reward Points:</span> <span>- {{ details?.appliedRewardPoints
                    | nepaliCurrency }}</span></p>

            <p class="grand_total_order"><span>Grand Total:</span> <strong>{{details?.billingTotal | nepaliCurrency
                    }}</strong>
            </p>
        </div>
    </div>
</ng-container>