<div class="confirmation_dialogsec">
    <div class="message">
        <h2>{{data.header}}</h2>
        <p>{{data.message}}</p>
    </div>
    <div class="actions">
        <div class="confirm">
            <button (click)="confirm()" (keypress)="confirm()">
                Yes
            </button>
        </div>
        <div class="cancel">
            <button (click)="cancel()" (keypress)="cancel()">
                No
            </button>
        </div>
    </div>
</div>