<form [formGroup]="addressForm" (ngSubmit)="editAddress()" class="add_address_container">
  <div mat-dialog-actions class="header">
    <span class="title">Edit Address</span>
    <span style="cursor: pointer;" mat-dialog-close><img loading="lazy" src="../../../../assets/icons/icon-close.svg"
        alt="Buy Makeup Product Online | Foreveryng"></span>
  </div>
  <div mat-dialog-content>
    <div class="wrap_addresses_forms">
      <div class="fields_address" appearance="outline">
        <label for="name">Name</label>
        <input matInput id="name" placeholder="Name" formControlName="name">
        <div class="error-message"
          *ngIf="addressForm.get('name')?.touched && addressForm.get('name')?.hasError('required')">
          Name is required.
        </div>
      </div>
      <div class="fields_address" appearance="outline">
        <label for="phone">Phone</label>
        <input matInput id="phone" type="number" placeholder="Phone" formControlName="phone">
        <div class="error-message"
          *ngIf="addressForm.get('phone')?.touched && addressForm.get('phone')?.hasError('required')">
          Number is required.
        </div>
      </div>
      <div class="fields_address" appearance="outline">
        <label for="alt_phone">Alt. Phone (Optional)</label>
        <input matInput id="alt_phone" type="number" placeholder="Phone" formControlName="alt_phone">
      </div>
      <div class="fields_address" appearance="outline">
        <button type="button" class="search-address-btn" (click)="searchAddress()">Search Address</button>
        <div class="selected-address" *ngIf="selectedAddress">
          <div>Province: {{ selectedAddress.province_name }}</div>
          <div>City: {{ selectedAddress.city_name }}</div>
          <div>Area: {{ selectedAddress.area_name }}</div>
        </div>
        <div class="error-message"
          *ngIf="!addressForm.get('city_id')?.hasError('required') && addressForm.get('area_id')?.touched && addressForm.get('area_id')?.hasError('required')">
          Address is required.
        </div>
      </div>
      <div class="fields_address" appearance="outline">
        <label for="address">Landmark</label>
        <textarea matInput id="address" placeholder="Address" formControlName="address"></textarea>
        <div class="error-message"
          *ngIf="addressForm.get('address')?.touched && addressForm.get('address')?.hasError('required')">
          Landmark is required.
        </div>
      </div>
      <div class="is_default fields_address">
        <label for="is_default">is default</label>
        <input type="checkbox" id="is_default" formControlName="is_default">
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="footer_address_sec">
    <button color="warn" tabindex="0" (click)="deleteAddress()" (keypress)="deleteAddress()" type="button"
      *ngIf="! addressForm.value.is_default">Delete</button>
    <button color="primary" type="submit">Submit</button>
  </div>
</form>