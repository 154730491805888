import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, map, shareReplay } from 'rxjs';
import { AppState } from 'src/app/store/app.store';
import { getCategoriesData } from 'src/app/store/catalog/categories/category.selectors';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { getHomeData } from 'src/app/store/home/home.selectors';
import { category } from 'src/app/core/interface/category';
import { home } from 'src/app/core/interface/home';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  categories$: Observable<category[]> = EMPTY;
  home$: Observable<home> = EMPTY;

  isHandset$: Observable<boolean> = this._breakpointObserver.observe([Breakpoints.Handset, Breakpoints.TabletPortrait])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(
    private _breakpointObserver: BreakpointObserver,
    private _store: Store<AppState>
  ) { }

  ngOnInit() {
    this.categories$ = this._store.select(getCategoriesData);
    this.home$ = this._store.select(getHomeData);
  }
}
