import { createReducer, on } from '@ngrx/store';
import { AddressActions } from './address.actions';

export const addressFeatureKey = 'address';

export interface State {
  data: any,
  status: string,
  cities: any
}

export const initialState: State = {
  data: null,
  status: 'pending',
  cities: null
};

export const reducer = createReducer(
  initialState,
  on(
    AddressActions.loadAddressSuccess,
    (state, action) => {
      return {
        ...state,
        data: action.data,
        status: 'success'
      }
    }
  ),
  on(
    AddressActions.loadCitiesSuccess,
    (state, action) => {
      return {
        ...state,
        cities: action.data
      }
    }
  )
);

