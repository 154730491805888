import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const WishlistActions = createActionGroup({
  source: 'Wishlist',
  events: {
    'Load Wishlist': emptyProps(),
    'Load Wishlist Success': props<{ data: any }>(),
    'Remove Wishlist Data': emptyProps(),
    'Add To Wishlist': props<{ attribute_product_id: number }>(),
    'Remove Form Wishlist': props<{ product_id: number }>(),
    'Notify Me': props<{ attribute_product_id: number }>()
  }
});
