import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { EMPTY, ErrorNotification, Observable, map } from 'rxjs';
import { API_ROUTES } from 'src/app/core/constants/api.constants';
import { SearchAreaInterface, address, city } from 'src/app/core/interface/address';
import { ApiHttpService } from 'src/app/core/services/api-http.service';
import { AddressActions } from 'src/app/store/address/address.actions';
import { AppState } from 'src/app/store/app.store';
import { CartActions } from 'src/app/store/cart/cart.actions';
import { SearchAddressComponent } from '../search-address/search-address.component';

@Component({
  selector: 'app-edit-address',
  templateUrl: './edit-address.component.html',
  styleUrls: ['./edit-address.component.scss']
})
export class EditAddressComponent implements OnInit {
  addressForm = this._fb.group({
    delivery_address_id: [this._data.address.id, Validators.required],
    name: [this._data.address.name, Validators.required],
    address: [this._data.address.address, Validators.required],
    phone: [this._data.address.phone, Validators.required],
    alt_phone: this._data.address.phoneAlt,
    province_id: [this._data.address.province?.id, Validators.required],
    city_id: [this._data.address.city.id, Validators.required],
    area_id: [this._data.address.area?.id, Validators.required],
    is_default: [this._data.address.isDefault],
  });
  selectedAddress?: SearchAreaInterface;

  constructor(
    private _fb: FormBuilder,
    private _api: ApiHttpService,
    private _store: Store<AppState>,
    private _dialog: MatDialog,
    private _dialogRef: MatDialogRef<EditAddressComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: { address: address }
  ) {
  }
  ngOnInit() {
    this.selectedAddress = {
      area_id: this._data.address.area?.id ?? 0,
      area_name: this._data.address.area?.name ?? '',
      city_id: this._data.address.city.id,
      city_name: this._data.address.city.name,
      province_id: this._data.address.province?.id ?? 0,
      province_name: this._data.address.province?.name ?? ''
    }
  }

  editAddress() {
    this.addressForm.markAllAsTouched();
    if (this.addressForm.valid) {
      const addressSub = this._api.post(API_ROUTES.updateAddress, this.addressForm.value).subscribe({
        next: () => {
          this._store.dispatch(AddressActions.loadAddress());
          this._store.dispatch(CartActions.reloadCart());
        },
        complete: () => {
          this._dialogRef.close();
          addressSub.unsubscribe();
        },
        error: (err: ErrorNotification) => {
          addressSub.unsubscribe();
        }
      });
    }
  }

  deleteAddress() {
    const addressSub = this._api.post(API_ROUTES.deleteAddress, { delivery_address_id: this.addressForm.value.delivery_address_id }).subscribe({
      next: () => {
        this._store.dispatch(AddressActions.loadAddress());
        this._store.dispatch(CartActions.reloadCart());
      },
      complete: () => {
        this._dialogRef.close();
        addressSub.unsubscribe();
      },
      error: (err: ErrorNotification) => {
        addressSub.unsubscribe();
      }
    });
  }

  searchAddress() {
    this._dialog.open(SearchAddressComponent).afterClosed().subscribe(res => {
      if (res) {
        this.selectedAddress = res.address;
        this.addressForm.get('province_id')?.setValue(res.address.province_id);
        this.addressForm.get('city_id')?.setValue(res.address.city_id);
        this.addressForm.get('area_id')?.setValue(res.address.area_id);
      }
    })
  }
}
