import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const HomeActions = createActionGroup({
  source: 'Home',
  events: {
    'Load Home': emptyProps(),
    'Home Data Load Success': props<{ data: any }>(),
    'Home Banners Load Success': props<{ data: any }>(),
    'Home Products Load Success': props<{ data: any }>(),
    'Home Brands Load Success': props<{ data: any }>(),
    'Home Categories Load Success': props<{ data: any }>(),
    'Close PopUp': emptyProps(),
  }
});
