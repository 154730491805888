import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  EMPTY,
  ErrorNotification,
  Observable,
  map,
  of,
  shareReplay,
} from 'rxjs';
import { AppState } from 'src/app/store/app.store';
import { AuthActions } from 'src/app/store/auth/auth.actions';
import {
  getCartCount,
  getCartData,
  getCartStatus,
} from 'src/app/store/cart/cart.selectors';
import { getCategoriesData } from 'src/app/store/catalog/categories/category.selectors';
import { getUserData, getUserStatus } from 'src/app/store/user/user.selectors';
import { ClickRouteService } from '../../services/click-route.service';
import { getBrandsData } from 'src/app/store/catalog/brands/brand.selectors';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CartActions } from 'src/app/store/cart/cart.actions';
import { ApiHttpService } from '../../services/api-http.service';
import { AutoSearchService } from '../../services/auto-search.service';
import { getWishlistCount } from 'src/app/store/wishlist/wishlist.selectors';
import { user_detail } from '../../interface/user';
import { cart } from '../../interface/cart';
import { API_ROUTES } from '../../constants/api.constants';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { NoticeService } from '../../services/notice.service';
import { UserActions } from 'src/app/store/user/user.actions';
import { getHomeData } from 'src/app/store/home/home.selectors';
import { home } from '../../interface/home';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  animations: [
    trigger('animateCart', [
      state('close', style({ width: 0, opacity: 0 })),
      state('open', style({ width: '*', opacity: 1 })),
      transition('close <=> open', animate('0.3s')),
    ]),
    trigger('dropdownCatalog', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate('0.3s', style({ height: '*', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ height: '*', opacity: 1 }),
        animate('0.3s', style({ height: 0, opacity: 0 })),
      ]),
    ]),
  ],
})
export class NavbarComponent implements OnInit {
  isHandset$: Observable<boolean> = this._breakpointObserver
    .observe([Breakpoints.Handset, Breakpoints.TabletPortrait])
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  userData$: Observable<user_detail> = EMPTY;
  isLoggedIn$: Observable<string> = EMPTY;
  cartCount$: Observable<number> = EMPTY;
  wishlistCount$: Observable<number> = EMPTY;
  navItems$: Observable<any> = EMPTY;
  viewMiniCart = false;
  cartUpdating: boolean[] = [];
  cartData$: Observable<cart> = EMPTY;
  viewAutoSearch$: Observable<boolean> = of(false);
  suggestionsData: NonNullable<unknown> | null = null;
  offersRedirectData$: Observable<home['navbarOffer']> = EMPTY;

  subCatalogData: {
    display: boolean;
    type: 'category' | 'brand';
    data: any;
  } = {
    display: false,
    type: 'category',
    data: null,
  };

  constructor(
    private _breakpointObserver: BreakpointObserver,
    private _router: Router,
    private _store: Store<AppState>,
    private _clickNav: ClickRouteService,
    private _api: ApiHttpService,
    private _autoSeatch: AutoSearchService,
    private _notice: NoticeService
  ) {}

  ngOnInit() {
    this.wishlistCount$ = this._store.select(getWishlistCount);
    this.isLoggedIn$ = this._store.select(getUserStatus);
    this.userData$ = this._store.select(getUserData);
    this.cartCount$ = this._store.select(getCartCount);
    this.navItems$ = this._store.select(getCategoriesData);
    this.cartData$ = this._store.select(getCartData);
    this.offersRedirectData$ = this._store
      .select(getHomeData)
      .pipe(map((res) => res?.navbarOffer));
    this.showHideNav();
    const suggestionSub = this._api
      .get(API_ROUTES.searchSuggestions)
      .subscribe({
        next: (res: any) => {
          this.suggestionsData = res.data;
        },
        complete: () => {
          suggestionSub.unsubscribe();
        },
        error: (err: ErrorNotification) => {
          suggestionSub.unsubscribe();
        },
      });
    this.viewAutoSearch$ = this._autoSeatch.getStatus();
  }

  openLogin() {
    this._router.navigate(['/auth'], {
      queryParams: { returnUrl: this._router.url },
    });
  }

  logOutUser() {
    this._store.dispatch(AuthActions.logout());
  }

  showSubCatalog(itemID: number, type: 'category' | 'brand' = 'category') {
    let data;
    if (type == 'category') {
      this.navItems$.subscribe((res) => {
        const item = res.find((x: any) => x.id === itemID);
        data = item;
      });
      this.subCatalogData = {
        display: true,
        type: 'category',
        data: data,
      };
    } else {
      this._store.select(getBrandsData).subscribe((res: any) => (data = res));
      this.subCatalogData = {
        display: true,
        type: 'brand',
        data: data,
      };
    }
  }

  closeSubCatalog() {
    this.subCatalogData = {
      display: false,
      type: 'category',
      data: null,
    };
  }

  handleClick(obj: {
    attribute: string;
    filter: NonNullable<unknown>;
    id: number;
    title: string;
    slug: string;
  }) {
    this._clickNav.handleClick(
      obj.attribute,
      obj.filter,
      obj.id,
      obj.title,
      obj.slug
    );
    this.subCatalogData.display = false;
  }

  removeFromCart(id: number) {
    this.cartUpdating[id] = true;
    this._store.dispatch(
      CartActions.updateCart({
        params: { cart_item_id: id, is_cart: 1, quantity: 0 },
      })
    );
    this._store.select(getCartStatus).subscribe((status) => {
      if (status == 'success') {
        this.cartUpdating[id] = false;
      }
    });
  }

  adjustCartItemQuantity(id: number, quantity: number) {
    if (quantity < 0) {
      return;
    }
    this.cartUpdating[id] = true;
    this._store.dispatch(
      CartActions.updateCart({
        params: { cart_item_id: id, is_cart: 1, quantity: quantity },
      })
    );
    this._store.select(getCartStatus).subscribe((status) => {
      if (status !== 'updating') {
        this.cartUpdating[id] = false;
      }
    });
  }

  showHideNav() {
    let lastScrollTop = document.documentElement.scrollTop;
    window.addEventListener(
      'scroll',
      function handleScroll() {
        const scrollTopPosition = document.documentElement.scrollTop;
        const nav = document.querySelector('.catlist_header') as HTMLElement;
        const notice = document.querySelector(
          '.topaddbanner_header'
        ) as HTMLElement;
        const navSearch = document.querySelector(
          '.handset-container .search .search-bar'
        ) as HTMLElement;
        if (scrollTopPosition > lastScrollTop) {
          notice ? (notice.style.height = '0') : null;
          navSearch ? (navSearch.style.display = 'none') : null;
        } else if (scrollTopPosition < lastScrollTop) {
          notice ? (notice.style.height = '40px') : null;
          navSearch ? (navSearch.style.display = 'flex') : null;
        }
        lastScrollTop = scrollTopPosition <= 0 ? 0 : scrollTopPosition;
      },
      false
    );
  }

  viewAutoSearch() {
    this._autoSeatch.open();
  }

  closeAutoSearch() {
    this._autoSeatch.close();
  }

  claimDailyReward() {
    this._api.post(API_ROUTES.claimDailyReward).subscribe({
      next: (res: any) => this._notice.addNotice(res.message),
      error: () => this._store.dispatch(UserActions.loadUser()),
      complete: () => this._store.dispatch(UserActions.loadUser()),
    });
  }
}
