import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const CartActions = createActionGroup({
  source: 'Cart',
  events: {
    'Load Cart': emptyProps(),
    'Reload Cart': emptyProps(),
    'Remove Cart Data': emptyProps(),
    'Update Cart': props<{ params: any }>(),
    'Load Cart Success': props<{ data: any }>(),
    'Load Cart Failure': props<{ error: any }>(),
    'Add To Cart': props<{ id: number, quantity: number }>(),
    'Update Cart Items': props<{ cart_item_id: number, quantity: number }>(), //TODO: use insted of update cart for cart items

    //TODO: place to other actions
    'Load Payments': emptyProps(),
    'Load Payments Success': props<{ data: any }>(),
    'Checkout': props<{ params: any }>(),
    'Checkout Success': emptyProps(),
    'Verify Payment': props<{ method: string, params: any }>(),
    'Load Selectable Gifts': emptyProps(),
    'Load Selectable Gifts Success': props<{ data: any }>(),
  }
});
