import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { address } from 'src/app/core/interface/address';
import { AddAddressComponent } from 'src/app/features/address/add-address/add-address.component';
import { AddressComponent } from 'src/app/features/address/address.component';
import { AppState } from 'src/app/store/app.store';
import { CartActions } from 'src/app/store/cart/cart.actions';

@Component({
  selector: 'app-delivery-address',
  templateUrl: './delivery-address.component.html',
  styleUrls: ['./delivery-address.component.scss']
})
export class DeliveryAddressComponent {
  @Input() currentAddress!: address | null;
  @Input() billingEmail = '';

  constructor (
    private _dialog: MatDialog,
    private _store: Store<AppState>
  ) {}
  
  openAddressList() {
    this._dialog.open(AddressComponent,{
      width:'95vw',
    }).afterClosed().subscribe(
      (data: address) => data.id ? this._store.dispatch(CartActions.updateCart({ params: { delivery_address_id: data.id } })) : null
    )
  }

  addAddress() {
    this._dialog.open(AddAddressComponent);
  }
}
