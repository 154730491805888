<div class="nav-list-dashboard">
    <h3>My Account</h3>
    <a class="nav profile" [routerLink]="['/profile']" *ngIf="(isHandset$ | async) === false" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
        <div class="icon_dashboard">
            <img loading="lazy"  src="../../../assets/icons/dashboard/icon-user.svg" alt="Buy Makeup Product Online | Foreveryng">
        </div>
        My Account
    </a>
    <a class="nav user-profile" [routerLink]="['/profile/user-profile']" *ngIf="isHandset$ | async" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
        <div class="icon_dashboard">
            <img loading="lazy"  src="../../../assets/icons/dashboard/icon-user.svg" alt="Buy Makeup Product Online | Foreveryng">
        </div>
        Account Details
    </a>
    <a class="nav subscriptions" [routerLink]="['/profile/subscriptions']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
        <div class="icon_dashboard">
            <img loading="lazy"  src="../../../assets/icons/dashboard/icon-subscription.svg" alt="Buy Makeup Product Online | Foreveryng">
        </div>
        Subscription
    </a>
    <a class="nav verify-number" *ngIf="(userData$ | async)?.isVerifiedUser === false" [routerLink]="['/profile/verify-number']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
        <div class="icon_dashboard">
            <img loading="lazy"  src="../../../assets/icons/dashboard/icon-phone.svg" alt="Buy Makeup Product Online | Foreveryng">
        </div>
        Verify Number
    </a>
    <a class="nav refer" [routerLink]="['/profile/refer']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
        <div class="icon_dashboard">
            <img loading="lazy"  src="../../../assets/icons/dashboard/icon-subscription.svg" alt="Buy Makeup Product Online | Foreveryng">
        </div>
        Refer and Earn
    </a>
    <h3>My Orders</h3>
    <a class="nav orders" [routerLink]="['/orders']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
        <div class="icon_dashboard">
            <img loading="lazy"  src="../../../assets/icons/dashboard/icon-order.svg" alt="Buy Makeup Product Online | Foreveryng">
        </div>
        My Orders
    </a>
    <a class="nav canceled-orders" [routerLink]="['/orders/canceled-orders']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
        <div class="icon_dashboard">
            <img loading="lazy"  src="../../../assets/icons/dashboard/icon-order.svg" alt="Buy Makeup Product Online | Foreveryng">
        </div>
        My Cancel Orders
    </a>
    <h3>Gift Card</h3>
    <a class="nav private-coupons" [routerLink]="['/gift-card/all-orders']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
        <div class="icon_dashboard">
            <img loading="lazy"  src="../../../assets/icons/dashboard/icon-coupon.svg" alt="Buy Makeup Product Online | Foreveryng">
        </div>
       Sent Gift Cards
    </a>

    <a class="nav private-coupons" [routerLink]="['/gift-card']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
        <div class="icon_dashboard">
            <img loading="lazy"  src="../../../assets/icons/dashboard/icon-coupon.svg" alt="Buy Makeup Product Online | Foreveryng">
        </div>
        Send Gift Cards
    </a>
    <h3>Coupons & Gifts</h3>
    <a class="nav private-coupons" [routerLink]="['/profile/private-coupons']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
        <div class="icon_dashboard">
            <img loading="lazy"  src="../../../assets/icons/dashboard/icon-coupon.svg" alt="Buy Makeup Product Online | Foreveryng">
        </div>
        My Coupons
    </a>
    <a class="nav user-reviews" [routerLink]="['/reviews/user-reviews']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
        <div class="icon_dashboard">
            <img loading="lazy"  src="../../../assets/icons/dashboard/icon-review.svg" alt="Buy Makeup Product Online | Foreveryng">
        </div>
        My Reviews
    </a>
    <a class="nav wishlist" [routerLink]="['/profile/wishlist']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
        <div class="icon_dashboard">
            <img loading="lazy"  src="../../../assets/icons/dashboard/icon-wishlist.svg" alt="Buy Makeup Product Online | Foreveryng">
        </div>
        Wishlists
    </a>
    <!-- <a class="nav" [routerLink]="['/influencer']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
        <div class="icon_dashboard">
            <img loading="lazy"  src="../../../assets/icons/dashboard/icon-user.svg" alt="Buy Makeup Product Online | Foreveryng">
        </div>
        Influencer Collaboration
    </a> -->
    <h3>Account Settings</h3>
    <a class="nav delete-account" [routerLink]="['/profile/delete-account']" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{ exact: true }">
        <div class="icon_dashboard">
            <img loading="lazy"  src="../../../assets/icons/dashboard/icon-delete-account.png" alt="Delete Account | Foreveryng">
        </div>
        Delete Account
    </a>
</div>