<ng-container [ngSwitch]="bannerSectionData.type">
    <ng-container *ngSwitchCase="'ad_banner_2'">
        <!-- Coupon Add Banner-->
        <div class="ad_banner_sections light_background_theme">
            <div class="container">
                <div class="wrap_add_banners" [ngClass]="(isHandset$ | async) ? 'scroll' : 'auto_slide'" [ngStyle]="{ '--coupons': bannerSectionData.banners.length }">
                    <ng-container *ngFor="let banner of bannerSectionData.banners">
                        <img [src]="banner.image" alt="" (click)="bannerClick(banner)" (keypress)="bannerClick(banner)" tabindex="0">
                    </ng-container>
                    <ng-container *ngFor="let banner of bannerSectionData.banners">
                        <img [src]="banner.image" alt="" (click)="bannerClick(banner)" (keypress)="bannerClick(banner)" tabindex="0">
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'ad_banner_1'">
        <!-- Slider Add Banner-->
        <div class="ad_banner_sections_slide">
        <div class="container">
                <div class="sliders_wrap_adbanner">
                    <app-banner-carousel [banners]="bannerSectionData.banners" width="1600" height="466"></app-banner-carousel>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'main_ad_banner'">
        <!-- Slider Add Banner-->
        <div class="ad_banner_sections_slide">
        <div class="container">
                <div class="sliders_wrap_adbanner">
                    <app-banner-carousel [banners]="bannerSectionData.banners" width="1600" height="466"></app-banner-carousel>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngSwitchDefault>
        <!-- Others Add Banner-->
        <div class="container" style="margin-top: 35px;" *ngIf="bannerSectionData.banners && bannerSectionData.banners.length > 0">
            <a href="javascript:void(0)" (click)="bannerClick(bannerSectionData.banners[0])" (keypress)="bannerClick(bannerSectionData.banners[0])">
                <img [src]="bannerSectionData.banners[0].image" width="100%" alt="">
            </a>
        </div>
    </ng-container>
</ng-container>
