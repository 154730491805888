import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, map, shareReplay } from 'rxjs';
import { user_detail } from 'src/app/core/interface/user';
import { AppState } from 'src/app/store/app.store';
import { getUserData } from 'src/app/store/user/user.selectors';

@Component({
  selector: 'app-dashboard-nav',
  templateUrl: './dashboard-nav.component.html',
  styleUrls: ['./dashboard-nav.component.scss']
})
export class DashboardNavComponent {

  isHandset$: Observable<boolean> = this._breakpointObserver.observe([Breakpoints.Handset, Breakpoints.TabletPortrait])
    .pipe(
      map(result => result.matches),
      shareReplay()
    );


    userData$: Observable<user_detail> = EMPTY;
  constructor (
    private _breakpointObserver: BreakpointObserver,
    private _store: Store<AppState>
  ) {}

  ngOnInit() {
    this.userData$ = this._store.select(getUserData);
  }
}
