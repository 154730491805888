import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const AuthActions = createActionGroup({
  source: 'Auth',
  events: {
    'Attempt Login': props<{ params: any }>(),
    'Login Success': props<{ token: string }>(),
    'Login Failure': props<{ error: any }>(),
    'Attempt Register': props<{ params: any }>(),
    'Register Success': props<{ token: string }>(),
    'Register Failure': props<{ error: any }>(),
    // 'Attempt Social Login': props<{ method: 'google' | 'facebook' }>(),
    'Logout': emptyProps(),
    'Logout Failure': emptyProps(),
  }
});
