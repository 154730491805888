<div class="address_sections_details" *ngIf="currentAddress">
    <p>
        Deliver to: {{currentAddress.name}}
    </p>
    <p>
        {{currentAddress.phone}} | {{currentAddress.address}}, {{currentAddress.area?.name}}, {{currentAddress.city.name}}, {{currentAddress.province?.name}}
        <a class="chenge-address" href="javascript:void(0)" (click)="openAddressList()" (keypress)="openAddressList()">
            Change
        </a>
    </p>
    <p>
        Email to {{ billingEmail }}
    </p>
</div>

<div class="new-address" *ngIf="! currentAddress">
    <div tabindex="0" (click)="addAddress()" (keypress)="addAddress()" class="wrapaddressaddse">
        <p>Address Not Selected</p>
        <span><strong>+</strong>Click to add your delivery address</span>
    </div>
   
</div>