import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, DoCheck, Input, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, map, shareReplay } from 'rxjs';
import { API_ROUTES } from 'src/app/core/constants/api.constants';
import { ApiHttpService } from 'src/app/core/services/api-http.service';
import { AutoSearchService } from 'src/app/core/services/auto-search.service';
import { ClickRouteService } from 'src/app/core/services/click-route.service';

@Component({
  selector: 'app-auto-search',
  templateUrl: './auto-search.component.html',
  styleUrls: ['./auto-search.component.scss']
})
export class AutoSearchComponent implements OnInit, DoCheck {
  @Input() suggestionsData: any;
  private __breakpointObserver = inject(BreakpointObserver);
  isHandset$: Observable<boolean> = this.__breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(res => res.matches),
    shareReplay()
  )
  searchValue = '';
  searchData: any;
  oldSearchValue = '';

  ngOnInit() {
    const input = document.querySelector('.autosearch_form_fields .search-input') as HTMLElement;
    input.focus();
  }

  ngDoCheck() {
    if (this.searchValue !== this.oldSearchValue) {
      this.oldSearchValue = this.searchValue;
      this.autoSearch();
    }
  }

  constructor(
    private _api: ApiHttpService,
    private _router: Router,
    private _autoSearch: AutoSearchService,
    private _clickRoute: ClickRouteService
  ) { }

  autoSearch() {
    const searchSub = this._api.get(API_ROUTES.autoSearch, { search_query: this.searchValue.replace(/[^a-zA-Z0-9 ]/g, '')}).subscribe({
      next: (res: any) => {
        this.searchData = res.data;
      },
      complete: () => {
        searchSub.unsubscribe();
      },
      error: (err: any) => {
        // console.log(err);
        searchSub.unsubscribe();
      }
    });
  }

  handleClick(param: any) {
    this._clickRoute.handleClick(param.attribute, param.filter, param.id, param.title, param.slug);
    this._autoSearch.close();
  }
}
