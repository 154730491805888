import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../../app.store';
import { categoryFeatureKey } from './category.reducer';

const getCategories = createFeatureSelector<AppState>(categoryFeatureKey);

export const getCategoriesData = createSelector(
    getCategories,
    (state: any) => {
        return state.data?.categories;
    }
)