import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { CdkService } from './cdk.service';

@Injectable({
  providedIn: 'root'
})
export class ApiHttpService {
  private base_url: string = environment.API_ENDPOINT;

  constructor(
    private _http: HttpClient,
    private _auth: AuthService,
    private _cdk: CdkService
  ) { }

  get(url: string, parameters?: any) {
    const token = this._auth.getToken();
    const header = new HttpHeaders().append('Authorization', 'Bearer ' + token);
    return this._http.get(this.base_url + url, { headers: header, params: parameters ? { ...parameters, source: 'website', device: this._cdk.deviceView } : { source: 'website', device: this._cdk.deviceView } })
  }

  post(url: string, parameters?: NonNullable<unknown> | null) {
    const token = this._auth.getToken();
    const header = new HttpHeaders().append('Authorization', 'Bearer ' + token);
    return this._http.post(this.base_url + url, parameters, { headers: header });
  }
}
