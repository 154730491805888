@if(chatView) {
<div class="chat-view">
  <div class="chat-container" #chatContainer>
    @for (event of messageList; track $index) {
    <div [ngClass]="event.message.sender.type" class="message-container">
      <div class="title">
        {{ event.message.sender.name }}
      </div>
      <div class="images">
        @for (link of event.message.files; track $index) {
        @if (link.type === "image") {
        <img [src]="link.link">
        }
        }
      </div>
      <div class="message-text">
        {{ event.message.text }}
      </div>
    </div>
    }
  </div>
  <div class="media">
    <div> Selected Images </div>
    @for (link of getLocalImages; track $index) {
      <img [src]="link">
    }
  </div>
  <form (ngSubmit)="sendMessage(input)" class="send-msg-form">
    <input type="text" #input>
    <input type="file" multiple #imageInput hidden (change)="addImage($event)">
    <button type="button" (click)="imageInput.click()"><mat-icon>add_photo_alternate</mat-icon></button>
    <button type="submit">send</button>
  </form>
</div>
}
<button (click)="triggerChat()" class="chat-trigger-btn">
  <mat-icon fontIcon="message"></mat-icon>
</button>
